import React from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import {GetDestinations, GetFlows, GetSites, GetSitesMinimumData, GetUserCreatedSite, GetUserCreatedSites, GetFlowsBySiteCount} from "../utils/NastaApiClient";
import {Site} from "../dataClasses/Site/Site";
import {Flow} from "../dataClasses/Flow";
import {Destination} from "../dataClasses/Destination/Destination";
import SearchBox from "../widgets/SearchBox/SearchBox";
import {updateSitesSelection, mapSelectionSelector} from "../mapViewOl/mapSelectionSelector";
import {default as MPagination} from "@mui/material/Pagination/Pagination";
import { useDispatch, useStore} from 'react-redux';

import {
    Stack,
    Paper,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
} from "@mui/material";
import UploadingNotification from "../widgets/UploadingNotification/UploadingNotification";
const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const storeHelper = useStore();

    const {t} = useTranslation('addCalcPlace', 'common')

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
            params={params}
            navigate={navigate}
            dispatch={dispatch}
            storeHelper={storeHelper}
        />
    );
};

class ListCalcPlace extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paginationOffset: 0,
            destinations: null,
            sites: null,
            flows: null,
            searchResults:[],
            filteredIdList:[],
            searchValue:null
        }
        this.loadData=this.loadData.bind(this);
        this.setPagination=this.setPagination.bind(this);
    }

    allLoaded() {

        let {
            destinations,
            sites,
            flows
        } = this.state;

        if (destinations !== null && sites !== null && flows !== null) {
            return true;
        }
        return false;
    }

    async componentDidMount() {
        
        if(typeof this.props.params.page!=='undefined'){
            this.setState({paginationOffset:(parseInt(this.props.params.page)-1)})
        }
        await this.loadData();

        this.setState({filteredIdList: this.props.storeHelper.getState().mapSelectionSelector.selections.selectedSites})
    }

    async loadData(){
        await GetDestinations().then(destinations => {
            this.setState({destinations: destinations.map(x => new Destination(x))})
        });

        await GetSitesMinimumData().then(sites => {
            this.setState({sites:sites.data});
        });

        await GetFlowsBySiteCount().then(flows => {
            this.setState({flows: flows.data});
        });
    }

    setPagination(pageNumber){

        let page=parseInt(pageNumber)

        let currentSite="all";
        if(typeof this.props.params.id!=='undefined'){
            currentSite=parseInt(this.props.params.id);
        }
        this.setState({paginationOffset: page-1}, ()=>{

            this.props.navigate("/site/list/"+currentSite+"/"+page)
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(typeof this.props.params.page!=='undefined'){
            let page = parseInt(this.props.params.page);

            if(page-1!==this.state.paginationOffset){
                this.setState({paginationOffset:(page-1)})
            }
        }

        let shouldReloadData=localStorage.getItem("calcPlaceListShouldReload");


        if(shouldReloadData){

            this.loadData();
            localStorage.removeItem("calcPlaceListShouldReload");
        }
    }


    render() {
        let {t} = this.props;

        /**
         *
         * @type {Destination[]}
         */
        let destinations = this.state.destinations;
        /**
         *
         *
         * @type {Site[]}
         */
        let sites = this.state.sites;

        /**
         *
         * @type {Flow[]}
         */
        let flows = this.state.flows;
        let searchFilterList = [];
        let maskedSiteList = sites;

        let slicedMaskedSiteList
        if (sites !== null) {
            maskedSiteList = sites.sort((a, b) => a.siteId > b.siteId ? -1 : 1);



            if(this.state.filteredIdList.length>0){
                maskedSiteList=maskedSiteList.filter(maskedSite=> this.state.filteredIdList.includes(maskedSite.siteId));



            if(this.state.searchValue!==null && this.state.searchValue!==""){

                searchFilterList=maskedSiteList.filter(site=>{

                    return this.state.searchResults.find(x=>{
                        return x.item.siteId===site.siteId
                    })

                });
                maskedSiteList=searchFilterList
                this.props.dispatch(updateSitesSelection({list:maskedSiteList.map(x=>x.siteId), listView:true}));
            }



            slicedMaskedSiteList = maskedSiteList.slice(this.state.paginationOffset * 50, (this.state.paginationOffset * 50) + 50)

            }else{
                searchFilterList=[];
                slicedMaskedSiteList=[];
            }
        }
        let currentPageMax=searchFilterList.length>0?searchFilterList.length:this.state.paginationOffset * 50+50>this.state.filteredIdList.length?this.state.filteredIdList.length:this.state.paginationOffset * 50+50




        let maxPages = 0;
        let uniques = [];
        if(this.state.filteredIdList.length>0) {

            if (this.state.searchResults.length !== 0) {

                for (const searchResult of this.state.searchResults) {
                    if (!uniques.some(u => u.id === searchResult.id)) {
                        uniques.push(searchResult)
                    }
                }
                currentPageMax = uniques.length > this.state.paginationOffset * 50 + 50 ? this.state.paginationOffset * 50 + 50 : uniques.length
                maxPages = Math.ceil(uniques.length / 50)
            } else {
                maxPages = Math.ceil(maskedSiteList?.length / 50)
            }

        }

        if(typeof this.props.params.page!=='undefined' && this.allLoaded() && maxPages!==0){
            if(parseInt(this.props.params.page)>maxPages){
                this.setPagination(1);
            }
        }

        return (

            <>


               {<UploadingNotification showModal={this.state.filteredIdList.length===0} />}
                {this.allLoaded() === true && <>
                    <SearchBox
                        sortByAttr={"siteId"}
                        titleAttrs={["siteId","siteName"]}
                        baseLinkUrl={"/site/view"}
                        idAttrField={"siteId"}
                        translator={t}
                        searchableFields={["siteId",
                        "siteDestination",
                        "responsibleName",
                        "siteName",

                        "landUseUnitId",
                        "landUseUnitTypeCodeId",
                        "paveSiteId",
                        "siteEcoVisio",

                        "destinations",
                        "siteOwnerCodeClassId",
                        "siteTypeCodeId",
                        "siteFunderCodeId",
                        "parkDistrictTypeCodeId",
                        "landUseUnitTypeCodeClassId",
                        "regionalUnitTypeCodeId",
                            "landUseUnitName",
                        "entityTypeCodeId",
                        "statusCodeId",
                        "createdBy",
                        "countyCodeId",
                        "modifiedBy",
                        "description",
                        "entity",
                        "createdTime",
                        "modifiedTime",
                        "parkDistrict",
                        "municipalityCodeId",
                        "municipality",
                        "regionalUnit"
                    ]}
                        indirectAttrFields={[["siteTypeCodeId", "siteTypeCodeClassId", "siteTypeCodeId"],
                            ["landUseUnitTypeCodeId", "landUseUnitTypeCodeClassId", "landUseUnitTypeCodeId"],
                            ["siteOwnerCodeId", "siteOwnerCodeClassId", "siteOwnerCodeId"],
                            ["siteFunderCodeId", "siteFunderCodeClassId", "siteFunderCodeId"],
                            ["parkDistrictTypeCodeId", "parkDistrictTypeCodeClassId", "parkDistrictTypeCodeId"],
                            ["municipalityCodeId", 280, "municipalityCodeId"],
                            ["countyCodeId", 1005, "countyCodeId"],
                            ["regionalUnitTypeCodeId", "regionalUnitClassCodeId", "regionalUnitTypeCodeId"],
                            ["entityTypeCodeId", "entityCodeClassId", "entityTypeCodeId"],
                            ["statusCodeId", "statusCodeClassId", "statusCodeId"],

                        ]}

                        combinedFields={[
                            ["landUseUnitId", "landUseUnitName"]

                        ]}
                        customSearchRuleFields={[

                            ["destinations", (destinations)=>{
                                if(Array.isArray(destinations) && destinations.length>0){

                                    let completeString="";
                                    for (const destination of destinations) {
                                        completeString+=destination.Id+" "+destination.Name;
                                    }
                                    return completeString;

                                }else{
                                    return ""
                                }

                            }],
                        ]}
                        dateAttrFields={["createdTime", "modifiedTime"]}
                        onResultsChanged={(results)=>{
                            if(results.length<this.state.paginationOffset*50){
                                this.setState({searchResults:results}, ()=>{
                                    this.setPagination(1);
                                })
                            }else{
                                this.setState({searchResults:results})
                            }

                            }
                        }
                        onSearchValueChanged={(searchValue)=>{
                            this.setState({searchValue:searchValue})
                        }}
                        items={sites.filter(site=>this.state.filteredIdList.includes(site.siteId))}
                        reloadData={this.loadData}
                    />


                    <Stack alignItems={"center"} spacing={2}>
                        <p style={{textAlign: "center",marginBottom:"-2vh"}}>
                            {((this.state.paginationOffset) * 50)+(currentPageMax>0?1:0)} - {currentPageMax} /
                            {searchFilterList.length>0?searchFilterList.length:this.state.filteredIdList.length>0?this.state.filteredIdList.length:0}
                        </p>
                        <MPagination
                            page={(maxPages*50)>maskedSiteList.length?this.state.paginationOffset+1:1}
                            onChange={(e,val)=>{
                                this.setPagination(val);
                            }}
                            count={maxPages}
                            boundaryCount={1}
                            siblingCount={4}
                            color={"primary"} />
                    </Stack>
                    <TableContainer component={Paper}>

                        <Table sx={{}} aria-label={t("flow_list")}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <b>
                                        {t('site_id_ID')}
                                        </b>
                                    </TableCell>
                                    <TableCell>
                                        <b>
                                        {t('site_name')}
                                        </b>
                                    </TableCell>
                                    <TableCell>
                                        <b>
                                        {t('related_flows')}
                                        </b>
                                    </TableCell>
                                    <TableCell>
                                        <b>
                                        {t('destination')}
                                        </b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {slicedMaskedSiteList.map(site => {
                                    return <>
                                        <TableRow>
                                            <TableCell>
                                                {site.siteId}
                                            </TableCell>

                                            <TableCell>
                                                <Link
                                                    to={"/site/view/" + site.siteId}>{site.siteName}
                                                </Link>
                                            </TableCell>

                                            <TableCell>{flows.filter(flow => flow.siteId === site.siteId).length>0?
                                                <Link to={"/flow/list/" + site.siteId}>
                                                    {t("related_flows")} ({flows.find(flow => flow.siteId === site.siteId).flowCount})
                                                </Link>:<>
                                                    {t("related_flows")} (0)
                                                </>
                                            }

                                            </TableCell>


                                            <TableCell>
                                            {(Array.isArray(site.siteDestination) && site.siteDestination.length>0 && typeof site.siteDestination[0] !== 'undefined') && site.siteDestination.sort((a,b)=>a.destinationId<b.destinationId?1:-1).map((x,i,arr)=>{
                                                return (<>
                                                {typeof site.siteDestination[1] !== 'undefined' ?
                                                <TableRow>

                                                    <TableCell sx={{borderBottom:(i+1!==arr.length?'':'none')}}>
                                                        <Link to={"/destination/view/" + x.destinationId}>
                                                            {x.destinationId} {destinations.find(x=>x.destinationId===site?.siteDestination[i]?.destinationId)?.destinationName}
                                                        </Link>


                                                    </TableCell>


                                                </TableRow>:
                                                            <>
                                                        <Link to={"/destination/view/" + x.destinationId}>
                                                            {x.destinationId} {destinations.find(x=>x.destinationId===site?.siteDestination[i]?.destinationId)?.destinationName}
                                                        </Link>
                                                        <>
                                                        </>
                                                            </>

                                                }
                                                </>)



                                            })}
                                            </TableCell>

                                        </TableRow>

                                    </>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Stack alignItems={"center"} spacing={2}>
                        <p style={{textAlign: "center",marginBottom:"-2vh"}}>
                            {((this.state.paginationOffset) * 50)+(currentPageMax>0?1:0)} - {currentPageMax} /
                            {searchFilterList.length>0?searchFilterList.length:this.state.filteredIdList.length>0?this.state.filteredIdList.length:0}
                        </p>
                        <MPagination
                            page={this.state.paginationOffset+1}
                            onChange={(e,val)=>{
                                this.setPagination(val);
                            }}
                            count={maxPages}
                            boundaryCount={1}
                            siblingCount={4}
                            color={"primary"} />
                    </Stack>

                </>
                }
            </>
        )
    }
}

export default withRouter(ListCalcPlace)