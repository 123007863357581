import React from "react";
import MultiChoiceSearchDropdown from "../widgets/MultiChoiceSearchDropdown/MultiChoiceSearchDropdown";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";    
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import moment from "moment-timezone";
import 'moment/locale/fi'

import './AddCountingRule.css';
import ValidatedInputField from "../widgets/ValidatedInputField/ValidatedInputField";
import {useMsal} from "@azure/msal-react";
import {useNavigate, useParams, Link} from "react-router-dom";
import {AxiosError} from "axios";
import {
    GetCodeClasses,
    GetDestinations,
    GetSitesByDestinationId,
    GetFlowsBySiteId,
    InsertCountingRule,
    GetCountingRule,
    GetFlow,
    UpdateCountingRule,
    IsCountingRuleInUse,
    DeleteCountingRule
} from '../utils/NastaApiClient';
import FormController from "../widgets/FormController/FormController";

import { useSelector, useDispatch, useStore} from 'react-redux';
import * as codesSelector from '../mapViewOl/codesSelector';
import HourLineSelection from "./HourLineSelection";
import * as countingRuleDropdownDataSelector from './CountingRuleDropdownDataSelector';
import * as countingRuleTimeLimitsSelector from './CountingRuleTimeLimitsSelector';
import UploadingNotification from "../widgets/UploadingNotification/UploadingNotification";
import filterIcon from '../icons/Property 1=Filled-1.svg';
import InfoIcon from "@mui/icons-material/Info";
import {IconButton, Tooltip} from "@mui/material";
import CountingDialog from "../widgets/CountingDialog/CountingDialog";
import ModalComponent from "../widgets/ModalComponent/ModalComponent";

const daysInMonths = [31,29,31,30,31,30,31,31,30,31,30,31];

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const codes = useSelector((state) => state.codes);
    const timeLimits = useSelector((state) => state.countingRuleTimeLimitsSelector);
    const dropdownCommonSelections = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const storeHelper = useStore();

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
            params={params}
            navigate={navigate}
            dispatch={dispatch}
            storeHelper={storeHelper}
            codes={codes}
            timeLimits={timeLimits}
            dropdownCommonSelections={dropdownCommonSelections}
        />
    );
};

class AddCountingRule extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nonce: crypto.randomUUID(),
            submitAttempted: false,
            showSubmitModal: false,
            showErrorModal: false,
            showDeletionModal:false,
            choices: [],
            currentSearchValue: "",
            viewMode: this.props.additionalAttributes.countingRuleState,
            destinationsLoaded: false,
            sitesLoaded: false,
            dataLoads:{},
            allValid:false,
            coveragePercentage: '100,00',
            counts:null,
            countingRuleName: '',
            frequencyCodeId: undefined,
            frequencyCodeClassId: -1,
            isEffective: true,
            selectedFlows: [],
            expandedRows: [],
            pageUpdated: false,
            clearSitesStamp: new Date(),
            clearFlowsStamp: new Date(),
            responsibleEmail: '',
            deputyEmail: '',
            additionalInfo: '',
            userTypeCodeId: undefined,
            userTypeCodeClassId: -1,
            countingRuleId: -1,
            submitAttempted: false,
            showSubmitAlert: false,
            submitAlertMessage: '',
            submitErrors: [],
            countingRuleIsInUse: false,
            countingRulesLoaded: false,
            dialogOpen: false,
            showPublishButtonOnDialog: false,
            currentTitle: '',
            currentMessage: '', 
            id_when_changed: undefined,
            createdBy: typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '',
            createdTime: '',
            modifiedBy:  typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '',
            modifiedTime: '',
            submitErrorsModalOpen: false,
            submitErrorsModalTitle: "",
            submitErrorsModalContent: "",
            deleteRuleQuestionModal: false,
            onModalContinue:() => {
                this.setState({submitErrorsModalOpen:false});
            },
            showEditingModal: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getItemsFromCodeClass = this.getItemsFromCodeClass.bind(this);
        this.GetDestinationSelectionList = this.GetDestinationSelectionList.bind(this);
        this.GetSitesSelectionList = this.GetSitesSelectionList.bind(this);
        this.GetFlowsSelectionList = this.GetFlowsSelectionList.bind(this);
        this.GenQuickMultiSearchDropdown = this.GenQuickMultiSearchDropdown.bind(this);
        this.loadSitesByDestinationId = this.loadSitesByDestinationId.bind(this);
        this.loadFlowsBySiteId = this.loadFlowsBySiteId.bind(this);
        this.handleWeightChange = this.handleWeightChange.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.handleCountingRuleDelete = this.handleCountingRuleDelete.bind(this);
    }

    validateString(x) {
        if (typeof x === 'string' || x instanceof String) {
            if (x.length < 1) {
                throw new Error("Field length too low (0): " + x)
            }
            return x
        } else {
            throw new TypeError("invalid field value for: " + x + " (string required)")
        }
    }

    validateBoolean(x) {
        if (typeof x == "boolean") {
            return x
        } else {
            throw new TypeError("invalid field value for: " + x + " (boolean required)")
        }
    }

    validateInteger(x) {
        if (Number.isInteger(x)) {
            return x
        } else {
            try {
                return parseInt(x);
            } catch (e) {
                throw new Error("Field type not applicable integer");
            }
        }
    }

    async componentDidMount() {
        
        this.setState({countingRulesLoaded: false});
        this.props.dispatch(countingRuleDropdownDataSelector.resetAll());
        this.props.dispatch(countingRuleTimeLimitsSelector.resetAll());

        try {
            if(!this.props.storeHelper.getState().codesSelector.codes.codeClassesLoaded){
                let response = await GetCodeClasses();

                if (response instanceof AxiosError) {
                    this.setState({error: response});
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(false));
                } else {
                    this.props.dispatch(codesSelector.fillCodeClasses(response));
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(true));
                }
            }
        } catch (error) {
            console.log(error);
        }

        try
        {
            let response = await GetDestinations();
            if(response instanceof AxiosError){
                this.setState({error: response});
                this.setState({destinationsLoaded: false});
            }else{
                if(response.length > 0){
                    if(this.state.viewMode === 'view'){
                        this.props.dispatch(countingRuleDropdownDataSelector.changeDestinationList(response.sort((a,b) => b.destinationId - a.destinationId)));
                    }else{
                        this.props.dispatch(countingRuleDropdownDataSelector.changeDestinationList(response.filter(r => r.inUse === true).sort((a,b) => b.destinationId - a.destinationId)));
                    }
                    
                    this.setState({destinationsLoaded: true});
                }
            }
        }
        catch(error){
            console.log(error);
        }

        if(this.props.params.id !== undefined){
            try
            {
                let response = await IsCountingRuleInUse(this.props.params.id);
                if(response instanceof AxiosError){
                    this.setState({error: response});
                    this.setState({IsCountingRuleInUse: false});
                }else{
                        this.setState({IsCountingRuleInUse: response.data});
                }
            }
            catch(error){
                console.log(error);
            }
        }

        
        this.setState({clearSitesStamp: new Date()});
        this.setState({clearFlowsStamp: new Date()});
        
        this.props.dispatch(countingRuleTimeLimitsSelector.changeEditingState(false));

        if(this.state.viewMode === 'view' || this.state.viewMode === 'edit'){
            try
            {
                if(this.state.viewMode === 'view'){
                    this.props.dispatch(countingRuleTimeLimitsSelector.changeEditingState(true));
                }

                if( typeof this.props.params.id !== 'undefined'){
                    let response = await GetCountingRule(this.props.params.id);

                    if(response instanceof AxiosError){
                        this.setState({error: response});
                        this.setState({destinationsLoaded: false});
                    }else{
                        if(typeof response.data !== 'undefined'){
                            //Fill data
                            if(response.data !== null){

                                this.props.dispatch(countingRuleDropdownDataSelector.changeCountingRuleEnd(response.data.countingRuleEnd));
                                this.props.dispatch(countingRuleDropdownDataSelector.changeCountingRuleStart(response.data.countingRuleStart));

                                let destination = this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.destinations.find(f => f.destinationId === response.data.destinationId);

                                if(destination !== undefined){
                                    await this.loadSitesByDestinationId(destination.destinationId);
                                    this.props.dispatch(countingRuleDropdownDataSelector.changeDestination(destination));
                                }

                                if(typeof response.data.selectedSites !== 'undefined' && response.data.selectedSites !== null){
                                    let sites = [];
                                    response.data.selectedSites.forEach(element => {
                                        if(!sites.some(a => a.siteId === element)){
                                            sites.push(this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.sites.find(f => f.siteId === element));
                                        }
                                    });

                                    this.props.dispatch(countingRuleDropdownDataSelector.changeSelectedSitesList(sites.map(x => {
                                        {
                                            return {id: x.siteId,
                                                name: x.siteName,
                                                title: x.siteId.toString(),
                                                icon: "chip"}
                                        }
                                    })));
                                }

                                if(typeof response.data.selectedFlows !== 'undefined' && response.data.selectedFlows !== null){

                                    if(typeof this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.selectedSites !== 'undefined' &&
                                        this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.selectedSites !== null && 
                                        this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.selectedSites.length > 0){

                                        let fls = [];

                                        await Promise.all(this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.selectedSites.map(async (element) => {
                                            let flows = await this.loadFlowsBySiteId(parseInt(element.id));
                                            if(flows !== undefined){
                                                flows.forEach(f => {
                                                    fls.push(f);
                                                });
                                            }
                                        }));

                                        this.props.dispatch(countingRuleDropdownDataSelector.changeFlows(fls));
                                    }

                                    let fls = [];
                                   
                                    await Promise.all(response.data.selectedFlows.map(async (element) => {
                                        let flows = await GetFlow(element);
                                        fls.push(flows.data);
                                    }));

                                    this.props.dispatch(countingRuleDropdownDataSelector.changeSelectedFlows(fls.map(x => {
                                        {
                                            if(x !== undefined){
                                            let name = x.flowName;

                                            if(typeof x.flowDirectionType !== 'undefined' && x.flowDirectionType !== null){
                                                name += " " + x.flowDirectionType.languages[x.flowDirectionType.languages.findIndex(i => i.languageName === this.props.t('common:GMLLanguage'))].text;
                                            }
                                            if(typeof x.userType !== 'undefined' && x.userType !== null){
                                                name += " " + x.userType.languages[x.userType.languages.findIndex(i => i.languageName === this.props.t('common:GMLLanguage'))].text;
                                            }

                                            return {id: x.flowId,
                                                name: name,
                                                title: x.flowId.toString(),
                                                icon: "chip",
                                                flowId: x.flowId,
                                                flowDirectionType: x.flowDirectionType,
                                                userType: x.userType,
                                                weight: 1}
                                            }                                        
                                        }
                                    })));

                                   
                                }
                                
                                if(typeof response.data.flowWeights !== 'undefined' && response.data.flowWeights !== null){
                                    let fweights = response.data.flowWeights.map(x => {
                                        return {flowId: x.flowId, weight: x.weight}
                                    });
                                    this.props.dispatch(countingRuleDropdownDataSelector.changeWeightInputs(fweights));
                                }

                                if(typeof response.data.timeLimits !== 'undefined' && response.data.timeLimits !== null){
                                    let timeLimits = response.data.timeLimits.map(x => {
                                        return {flowId: x.flowId, hourLines: x.hourLines}
                                    });
                                    this.props.dispatch(countingRuleTimeLimitsSelector.loadDataToTimeLimitsTable(timeLimits));
                                }

                                this.setState({
                                    startDate: response.data.countingRuleStart,
                                    endDate: response.data.countingRuleEnd,
                                    additionalInfo: response.data.additionalInfo,
                                    countingRuleName: response.data.countingRuleName,
                                    coveragePercentage: response.data.coveragePercentage.toFixed(2).replace('.',','),
                                    responsibleEmail: response.data.responsibleEmail,
                                    deputyEmail: response.data.deputyEmail,
                                    isEffective: response.data.isEffective,
                                    nonce: response.data.nonce,createdBy: this.props.instance.getAllAccounts().length > 0 ? this.props.instance.getAllAccounts()[0].name : '',
                                    createdBy: this.state.viewMode === 'add' ? this.props.instance.getAllAccounts().length > 0 ? this.props.instance.getAllAccounts()[0].name : response.data.createdBy : response.data.createdBy, 
                                    createdTime: moment(response.data.createdTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(process.env.REACT_APP_NASTA_DATE_FORMAT),
                                    modifiedBy: this.state.viewMode === 'edit' ? this.props.instance.getAllAccounts().length > 0 ? this.props.instance.getAllAccounts()[0].name : response.data.modifiedBy : response.data.modifiedBy,
                                    modifiedTime: moment(response.data.modifiedTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(process.env.REACT_APP_NASTA_DATE_FORMAT),
                                    userTypeCodeId: response.data.userTypeCodeId,
                                    userTypeCodeClassId: response.data.userTypeCodeClassId,
                                    frequencyCodeId: response.data.frequencyCodeId,
                                    frequencyCodeClassId: response.data.frequencyCodeClassId,
                                    countingRuleId: response.data.countingRuleId,
                                });

                            }
                        }
                    }
                }
            }
            catch(error){
                console.log(error);
            }
        }
        this.setState({countingRulesLoaded: true});
    }

    getItemsFromCodeClass(codeClassName) {
        if (this.props.storeHelper.getState().codesSelector.codes.codeClasses.length > 0) {
            const codeClass = this.props.storeHelper.getState().codesSelector.codes.codeClasses.find(cc => cc.name === codeClassName);
            return codeClass.codes.map((code) => {
                const foundClass = code.languages.find(c => c.languageName === this.props.t('common:GMLLanguage'));
                if (typeof foundClass !== 'undefined') {
                    return {
                        id: code.codeId,
                        name: foundClass.text,
                        title: code.codeId.toString()
                    }
                }
            })
        }
    }

    async handleSubmit(event) {

        this.setState({showSubmitAlert: false})

        if(this.state.countingRuleName === ''){
            this.setState({showSubmitAlert: true, submitAlertMessage: this.props.t('addCountingRule:error_message_counting_rule_empty')});
            return;
        }
        if(this.state.responsibleEmail === ''){
            this.setState({showSubmitAlert: true, submitAlertMessage: this.props.t('addCountingRule:error_message_responsible_email_empty')});
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if(!emailRegex.test(this.state.responsibleEmail) || this.state.responsibleEmail.length > 200 || this.state.responsibleEmail === ''){
            this.setState({showSubmitAlert: true, submitAlertMessage: this.props.t('addCountingRule:error_message_email')});
            return;
        }

        if(this.state.deputyEmail !== ''){
            if(!emailRegex.test(this.state.deputyEmail) || this.state.deputyEmail.length > 200 || this.state.deputyEmail === ''){
                this.setState({showSubmitAlert: true, submitAlertMessage: this.props.t('addCountingRule:error_message_email')});
                return;
            }
        }

        this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.weightInputs.forEach(w => {
            if(w.weight === undefined || w.weight === null || w.weight === '' || isNaN(parseFloat(w.weight)) || parseFloat(w.weight) < -1 || parseFloat(w.weight) > 2){
                this.setState({showSubmitAlert: true, submitAlertMessage: this.props.t('addCountingRule:error_message_weight_error')});
                return;
            }
        });

            let end = this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleEnd.split('.');
            let start = this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleStart.split('.');


            if(this.state.frequencyCodeId === 1){
                if(parseInt(end[0]) !== 31 || parseInt(end[1]) !== 12 || parseInt(start[0]) !== 1 || parseInt(start[1]) !== 1){
                    this.setState({showSubmitAlert: true, submitAlertMessage: this.props.t('addCountingRule:error_message_yearly_frequence')});
                    return;
                }
            }

            if(this.state.frequencyCodeId === 2){
                if(parseInt(start[0]) !== 1 || parseInt(end[0]) !== daysInMonths[parseInt(end[1]) - 1]){
                    this.setState({showSubmitAlert: true, submitAlertMessage: this.props.t('addCountingRule:error_message_monthly_frequence')});
                    return;
                }
            }

            if(this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.destination.destinationId === undefined || this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.destination.destinationId === null){
                this.setState({showSubmitAlert: true, submitAlertMessage: this.props.t('addCountingRule:error_message_destinationId')});
                return;
            }
            if(this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.selectedFlows.map(m => m.flowId) === undefined || this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.selectedFlows.map(m => m.flowId) === null || this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.selectedFlows.map(m => m.flowId).length === 0){
                this.setState({showSubmitAlert: true, submitAlertMessage: this.props.t('addCountingRule:error_message_flows')});
                return;
            }
            if(this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits === undefined || this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits === null || this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.length === 0){
                this.setState({showSubmitAlert: true, submitAlertMessage: this.props.t('addCountingRule:error_message_timelimits')});
                return;
            }
            if(this.state.userTypeCodeId === undefined || this.state.userTypeCodeId === null){
                this.setState({showSubmitAlert: true, submitAlertMessage: this.props.t('addCountingRule:error_message_user_type_required')});
                return;
            }
            if(this.state.frequencyCodeId === undefined || this.state.frequencyCodeId === null){
                this.setState({showSubmitAlert: true, submitAlertMessage: this.props.t('addCountingRule:error_message_frequency_required')});
                return;
            }
            if(this.state.coveragePercentage === undefined || this.state.coveragePercentage === null || this.state.coveragePercentage === '' || isNaN(parseFloat(this.state.coveragePercentage)) 
                || parseInt(this.state.coveragePercentage) < 1 || parseInt(this.state.coveragePercentage) > 200 )
            {
                this.setState({showSubmitAlert: true, submitAlertMessage: this.props.t('addCountingRule:error_message_coverage_percentage')});
                return;
            }

            this.setState({submitAttempted: true});
            let submitThis = {
                additionalInfo: this.state.additionalInfo,
                countingRuleName: this.state.countingRuleName,
                coveragePercentage: parseFloat(this.state.coveragePercentage.replace(',','.')).toFixed(2),
                responsibleEmail: this.state.responsibleEmail,
                deputyEmail: this.state.deputyEmail,
                isEffective: this.state.isEffective,
                nonce: this.state.nonce,
                countingRuleEnd: this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleEnd,
                countingRuleStart: this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleStart,
                destinationId: this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.destination.destinationId,
                selectedFlows: this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.selectedFlows.map(m => m.flowId),
                timeLimits: this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits,
                createdBy: this.props.instance.getAllAccounts().length > 0 ? this.props.instance.getAllAccounts()[0].name : '',
                modifiedBy: this.props.instance.getAllAccounts().length > 0 ? this.props.instance.getAllAccounts()[0].name : '',
                userTypeCodeId: this.state.userTypeCodeId,
                userTypeCodeClassId: this.state.userTypeCodeClassId,
                frequencyCodeId: this.state.frequencyCodeId,
                frequencyCodeClassId: this.state.frequencyCodeClassId,
                flowWeights: this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.weightInputs,
                IsUsedOnCounting: this.state.IsCountingRuleInUse,            
            };

            
            let response = this.state.viewMode === 'add' ? await InsertCountingRule(submitThis) : await UpdateCountingRule(this.state.countingRuleId, submitThis);

            if (response instanceof AxiosError) {
                this.setState({submitErrorsModalOpen:true, submitErrorsModalTitle:this.props.t("common:submit_error_title"), submitErrorsModalContent:this.props.t("common:submit_error_content") + response.message});
                this.setState({submitAttempted: false, submitErrors: response.response.data}, () => {this.props.dispatch(countingRuleTimeLimitsSelector.changeEditingState(true))});
            } else {
                this.setState({submitAttempted: false, viewMode: 'view'}, () => {this.props.dispatch(countingRuleTimeLimitsSelector.changeEditingState(true))});

                if( typeof response.data === 'string' && response.data.startsWith('in_use')){
                    this.setState({dialogOpen: true, id_when_changed:response.data.split(':')[1],  currentTitle: this.props.t('addCountingRule:warning_on_rule_editing_title'), currentMessage: this.props.t('addCountingRule:warning_on_rule_editing_text')});
                }else{
                    this.setState({submitErrorsModalOpen:true, 
                        submitErrorsModalTitle:"", 
                        submitErrorsModalContent:this.props.t("common:data_saved_succesfully"),
                        
                        onModalContinue:() => {
                            this.props.navigate("/countingRule/view/" + response.data);
                            window.location.reload();
                            this.setState({submitErrorsModalOpen:false});
                        }
                    });
                }
            }
            
    }

   

    GetDestinationSelectionList (props) {

        let destinationSelection = [];

        if (this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.destinations.length > 0) {
            if (typeof this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.destinations !== 'undefined') {
                destinationSelection = this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.destinations.map(x => {
                    {
                        return {id: x.destinationId,
                            name: x.destinationName,
                            title: x.destinationId.toString(),
                            icon: "chip"}
                    }
                });
            }
        }

        let selectedDestination = [];
        if(this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.destination !== undefined && this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.destination !== null){
            var viewedDestination = destinationSelection.find(f => f.id === this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.destination.destinationId);
            if(viewedDestination !== undefined){
                selectedDestination.push(viewedDestination);
            }
        }

            return <MultiChoiceSearchDropdown
                required={true}
                maxChoices={1}
                active={this.state.viewMode === 'view' || this.state.viewMode === 'edit' ? false : true}
                disabled={this.state.viewMode === 'view' || this.state.viewMode === 'edit' ? true : false}
                minChoices={1}
                title={this.props.t('addCountingRule:destinationId')}
                tooltip={this.props.t('addCountingRule:destinationId')}
                placeholder={this.props.t('addCountingRule:destinationId')}
                inputData={destinationSelection}
                validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                choices={selectedDestination}
                onChoicesChange={(choicesList) => {
                    if (choicesList.length > 0) {
                        this.props.dispatch(countingRuleDropdownDataSelector.changeDestination(this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.destinations.find(f => f.destinationId ===  parseInt(choicesList[0].id))));
                        try{
                            this.loadSitesByDestinationId(parseInt(choicesList[0].id));
                        }catch(error){
                            console.log(error);
                        }
                    }else{
                        this.props.dispatch(countingRuleDropdownDataSelector.clearAll());
                        this.props.dispatch(countingRuleDropdownDataSelector.changeFlows([]));
                        this.props.dispatch(countingRuleDropdownDataSelector.changeSitesList([]));
                        this.setState({clearSitesStamp: new Date(), clearFlowsStamp: new Date(), pageUpdated: true});
                    }
                }}
                onValidityChange={props.onValidityChange}
                choiceAttrsShown={['id', 'name']}

            />
        
    }

    async loadSitesByDestinationId(destinationId) {
        try
        {
            let response = await GetSitesByDestinationId(destinationId);
            if(response instanceof AxiosError){
                this.setState({error: response});
                this.setState({sitesLoaded: false});
            }else{
                if(response.data.length > 0){
                    if(this.state.viewMode === 'view'){
                        this.props.dispatch(countingRuleDropdownDataSelector.changeSitesList(response.data.sort((a,b) => b.siteId - a.siteId)));
                    }else{
                        this.props.dispatch(countingRuleDropdownDataSelector.changeSitesList(response.data.filter(r => r.status.codeId === 3).sort((a,b) => b.siteId - a.siteId)));
                    }
                    
                    this.setState({sitesLoaded: true});
                }else{
                    this.setState({sitesLoaded: true});
                }
            }
        }
        catch(error){
            console.log(error);
        }
    }

    GetSitesSelectionList = (props) => {

        let maxChoices = this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.sites.length;
        let siteSelection = [];

        if (this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.sites.length > 0) {
            if (typeof this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.sites !== 'undefined') {
                siteSelection = this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.sites.map(x => {
                    {
                        return {id: x.siteId,
                            name: x.siteName,
                            title: x.siteId.toString(),
                            icon: "chip"}
                    }
                });
            }
        }

        let selectedSites = [];
        if(this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.selectedSites !== undefined && this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.selectedSites !== null){
            this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.selectedSites.forEach(fe => {
                var viewedSite = siteSelection.find(f => f.id === fe.siteId);
                if(viewedSite !== undefined){
                    selectedSites.push(viewedSite);
                }
            });
        }

            return <MultiChoiceSearchDropdown
                required={true}
                maxChoices={maxChoices}
                active={this.state.viewMode === 'view' || this.state.IsCountingRuleInUse ? false : true}
                disabled={this.state.IsCountingRuleInUse || this.state.viewMode === 'view'}
                minChoices={1}
                title={this.props.t('addCountingRule:siteIds')}
                tooltip={this.props.t('addCountingRule:siteIds')}
                placeholder={this.props.t('addCountingRule:siteIds')}
                inputData={siteSelection}
                validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                choices={this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.selectedSites}
                choicesCleared={this.state.clearSitesStamp}
                onValidityChange={props.onValidityChange}
                onChoicesChange={async (choicesList) => {
                    if (choicesList.length > 0) {
                                             
                        this.props.dispatch(countingRuleDropdownDataSelector.changeSelectedSitesList([...choicesList]));

                        let fls = [];

                        await Promise.all(choicesList.map(async (element) => {
                            let flows = await this.loadFlowsBySiteId(parseInt(element.id));
                            if(flows !== undefined){
                                flows.forEach(f => {
                                    fls.push(f);
                                });
                            }
                          }));

                        this.props.dispatch(countingRuleDropdownDataSelector.changeFlows(fls));
                        this.setState({pageUpdated: true});
                    }
                }}
                choiceAttrsShown={['id', 'name']}

            />
    }

    async loadFlowsBySiteId(siteId) {
        try
        {
            let response = await GetFlowsBySiteId(siteId);
            if(response instanceof AxiosError){
                this.setState({error: response});
                this.setState({flowsLoaded: false});
            }else{
                this.setState({flowLoaded: true});
                return response.data;
            }    
                
        }
        catch(error){
            console.log(error);
        }
            
    }
    
    GetFlowsSelectionList(props) {

        let selectableFlows = this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.flows.length > 0 ? this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.flows.map(s => 
            {
                if(s !== undefined){
                    let name = s.flowName;

                    if(typeof s.flowDirectionType !== 'undefined' && s.flowDirectionType !== null){
                        name += " " + s.flowDirectionType.languages[s.flowDirectionType.languages.findIndex(i => i.languageName === this.props.t('common:GMLLanguage'))].text;
                    }
                    if(typeof s.userType !== 'undefined' && s.userType !== null){
                        name += " " + s.userType.languages[s.userType.languages.findIndex(i => i.languageName === this.props.t('common:GMLLanguage'))].text;
                    }
                    return {id: s.flowId,
                        name: name,
                        title: s.flowId.toString(),
                        icon: "chip",
                        userType: s.userType,
                        flowDirectionType: s.flowDirectionType,
                        flowId: s.flowId,
                        
                    }
                }
            }) : [];

        return <MultiChoiceSearchDropdown
            required={true}
            maxChoices={this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.flows.length}
            active={this.state.viewMode === 'view' || this.state.IsCountingRuleInUse ? false : true}
            disabled={this.state.IsCountingRuleInUse || this.state.viewMode === 'view'}
            minChoices={1}
            title={this.props.t('addCountingRule:counting_rule_counting_flows')}
            tooltip={this.props.t('addCountingRule:counting_rule_counting_flows_tooltip')}
            placeholder={this.props.t('addCountingRule:counting_rule_counting_flows_placeholder')}
            inputData={selectableFlows}
            validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
            onValidityChange={props.onValidityChange}
            choices={this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.selectedFlows}
            choicesCleared={this.state.clearFlowsStamp}
            onChoicesChange={(choicesList) => {
                if (choicesList.length > 0) {
                    let selection = [];
                    choicesList.forEach(c => {
                        selection.push({
                            id: c.id,
                            title: c.flowId.toString(),
                            flowId: c.flowId,
                            name: c.name,
                            flowDirectionType: c.flowDirectionType,
                            userType: c.userType,
                            weight: 1,
                          });
                         
                         if(this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.hourLineSelections.find(f => f.flowId === c.flowId) === undefined){
                            this.props.dispatch(countingRuleTimeLimitsSelector.addHourLineSelectionTable({flowId: c.flowId, hourLineSelections: [<HourLineSelection flowId={c.flowId} key={0} index={0}/>]}));
                        }
                        if(this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.weightInputs.find(f => f.flowId === c.flowId) === undefined){
                            this.props.dispatch(countingRuleDropdownDataSelector.changeWeightInputs([...this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.weightInputs, {flowId:c.flowId, weight:1}]));
                        }
                    })
                    this.props.dispatch(countingRuleDropdownDataSelector.changeSelectedFlows(selection));

                }else{
                    this.props.dispatch(countingRuleDropdownDataSelector.changeSelectedFlows([]));
                }

                this.setState({pageUpdated: true});
            }}
            choiceAttrsShown={['id', 'name']}
        />
}


    GenQuickMultiSearchDropdown(props) {
        let codeClass = props.storeHelper.getState().codesSelector.codes.codeClasses.find(cc => cc.name === props.dataName);

        if (typeof codeClass !== 'undefined') {
            let selectedItem = undefined;
            let choiceArray = codeClass.codes.map(code => {
                if (typeof code.languages[code.languages.findIndex(i => i.languageName === props.t('common:GMLLanguage'))] !== 'undefined' && typeof code.codeId !== 'undefined') return {
                    id: code.codeId,
                    name: code.languages[code.languages.findIndex(i => i.languageName === props.t('common:GMLLanguage'))].text,
                    title: code.codeId.toString()
                }
                else{
                    return{
                    id: -1,
                    name: 'not named',
                    title: '-1'}
                }
            });

            if (typeof props.selectedCodeId !== 'undefined') {
                selectedItem = choiceArray[choiceArray.findIndex(i => i.id === parseInt(props.selectedCodeId))];
            }
            return (
                <MultiChoiceSearchDropdown required={props.required}
                                           maxChoices={1}
                                           active={props.active}
                                           disabled={props.disabled}
                                           minChoices={1}
                                           validationMode={(props.submitAttempted ? 'always' : 'onSelect')}
                                           title={props.t('addCountingRule:' + props.title)}
                                           tooltip={props.t('addCountingRule:' + props.tooltip)}
                                           placeholder={props.t('addCountingRule:' + props.placeholder)}
                                           inputData={choiceArray}
                                           choices={this.state.viewMode === 'view' && (typeof selectedItem !== 'undefined' && selectedItem !== null ? [selectedItem] : [])}
                                           onChoicesChange={(choicesList) => {
                                               if (choicesList.length > 0) {
                                                    props.callThis(choicesList, codeClass)
                                               }
                                           }}
                                           onValidityChange={props.onValidityChange}

                />
            )
        } else {
            return (<>
                <label className="form-label"
                       htmlFor={props.dataName}>{props.t('addCountingRule:' + props.title)}<span
                       data-tooltip-id={"tooltip-"+ props.tooltip}
                       data-tooltip-html={props.t('addCountingRule:' + "tooltip_" + props.tooltip)}
                       data-tooltip-delay-hide={1000}> <img
                    width="15" alt={props.t("common:info_logo")}
                    src="/img/icon/JHT design system/Information Circle.svg"/></span></label>
                    <Tooltip style={{zIndex:50000, fontSize: 'small'}} noArrow  id={"tooltip-"+ props.tooltip} />
                <input className="form-input" id={props.dataName}
                       placeholder={"Missing codeClass data for " + props.t('addCountingRule:' + "placeholder_" + props.dataName)}></input></>)
        }
    }


    handleWeightChange(flowId, event) {
        if(this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.weightInputs.find(f => f.flowId === flowId) !== undefined){
            let copy = JSON.parse(JSON.stringify(this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.weightInputs));
            copy.find(f => f.flowId === flowId).weight = event.target.value;
            this.props.dispatch(countingRuleDropdownDataSelector.changeWeightInputs(copy));
            this.setState({pageUpdated: true});
        }
      }

      handleSelection(choiceList, codeClass) {
        switch (codeClass.name) {
            case 'countingFrequency':
                this.setState(state => {
                    state.frequencyCodeId = choiceList[0].id;
                    state.frequencyCodeClassId = codeClass.codeClassId;
                    return state
                });

                if(choiceList[0].id === 1){ //Yearly calculation
                    this.props.dispatch(countingRuleDropdownDataSelector.changeCountingRuleStart('1.1.'));
                    this.props.dispatch(countingRuleDropdownDataSelector.changeCountingRuleEnd('31.12.'));
                    this.setState({pageUpdated: true});
                }

                break;
            case 'userType':
                this.setState(state => {
                    state.userTypeCodeId = choiceList[0].id;
                    state.userTypeCodeClassId = codeClass.codeClassId;
                    return state
                });
                break;
   
            default:
                console.log(`Not found ${codeClass.codeClassId}.`);
        }
    }

    
      toggleRow = (flowId) => {

        const expandedRows = [...this.state.expandedRows];
        const index = expandedRows.indexOf(flowId);
        if (index >= 0) {
          expandedRows.splice(index, 1);
        } else {
          expandedRows.push(flowId);
        }
        this.setState({ expandedRows });
      };

      handleAddHourLineSelection = (flowId, event) => {
        let newHourLineSelections = this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.hourLineSelections.slice(); 
        let maxIndex = Math.max(...newHourLineSelections.find(f => f.flowId === flowId).hourLineSelections.map(item => item.props.index));
        this.props.dispatch(countingRuleTimeLimitsSelector.addHourLineSelectionRow({flowId: flowId, timeLimit: <HourLineSelection flowId={flowId} key={newHourLineSelections.find(f => f.flowId === flowId).hourLineSelections.length} index={maxIndex + 1}/>}));
      };
    
      handleDeleteHourLineSelection = (index, flowId, event) => {
        if(this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.hourLineSelections.find(f => f.flowId === flowId).hourLineSelections.length > 1){
            let deleteThis = this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.hourLineSelections.find(f => f.flowId === flowId).hourLineSelections.findIndex(f => f.props.index === index);
            this.props.dispatch(countingRuleTimeLimitsSelector.deleteHourLineSelectionRow({flowId: flowId, indexToDelete: deleteThis}));
        }
      };

      checkContainsFlowRuleSetFilters(flowId){
        let flowData = this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === flowId);
        let isEdited = false;
        
        if(flowData !== undefined && flowData.hourLines !== undefined && flowData.hourLines.length > 0){
            flowData.hourLines.forEach(h => {
                if(h.timeLimitEdited === true){
                    isEdited = true;
                }
            });
        }
        return isEdited;
      }

      async handleCountingRuleDelete(){
        try {
            if(this.state.countingRuleId !== undefined && this.state.countingRuleId !== null && this.state.countingRuleId !== ''){
                let response = await DeleteCountingRule(this.state.countingRuleId);

                if (response instanceof AxiosError) {

                    if(response.response.data.startsWith('in_use')){
                        this.setState({dialogOpen: true, id_when_changed:this.state.countingRuleId,  currentTitle: this.props.t('addCountingRule:error_on_rule_delete_title'), currentMessage: this.props.t('addCountingRule:error_on_rule_delete_text'), IsCountingRuleInUse: true, viewMode: 'view'});
                    }
                } else {
                    this.setState({deleteRuleQuestionModal: false, 
                        submitErrorsModalOpen:true, 
                        submitErrorsModalTitle:this.props.t("addCountingRule:title_delete_rule_title"), 
                        submitErrorsModalContent:this.props.t("addCountingRule:title_delete_rule_content"),
                        onModalContinue:() => {
                            this.setState({submitErrorsModalOpen:false});
                            this.props.navigate("/countingRule/list/1");
                        }
                    })
                    
                }
            }
        } catch (error) {
            console.log(error);
        }
      }

     render() {
        let {t} = this.props;
        return (
            <>
                   <>
                 
                        <form>

                            <UploadingNotification showModal={this.state.countingRulesLoaded === false && this.state.viewMode === 'view'} />
                            <FormController onValidityChanged={(validity)=>this.setState({allValid:validity})}>

                                { this.state.viewMode !== 'add' && <div className="form-group">
                                        <label className="form-label" htmlFor="input-example-1">{t("addCountingRule:counting_rule_id")}
                                         {(t("addCountingRule:counting_rule_id_tooltip")!==null && t("addCountingRule:counting_rule_id_tooltip")!=='') &&

                                                <Tooltip title={t("addCountingRule:counting_rule_id_tooltip")}>
                                                    <IconButton>
                                                        <InfoIcon/>
                                                    </IconButton>
                                                </Tooltip>}
                                            </label>
                                        <input className="form-input" disabled={true} name="destinationId" type="number"
                                               id="input-example-1"
                                               placeholder={t('addCountingRule:placeholder_counting_rule_id')}
                                               value={this.state.countingRuleId !== -1 ? this.state.countingRuleId : ''}></input>
                                </div>}

                                <this.GetDestinationSelectionList/>
                                
                                <this.GetSitesSelectionList/>
                                
                                <ValidatedInputField
                                        name={"countingRuleName"}
                                        fieldName={this.props.t('addCountingRule:counting_rule_name')}
                                        tooltip={this.props.t('addCountingRule:counting_rule_name_tooltip')}
                                        placeholder={this.props.t('addCountingRule:counting_rule_name_placeholder')}
                                        required={true}
                                        disabled={this.state.viewMode === 'view' ? true : false}
                                        minLength={0}
                                        maxLength={100}
                                        type={"text"}
                                        fieldId={'countingRuleName'}
                                        validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                        value={this.state.countingRuleName}
                                        validatorFunction={() => {
                                            return true
                                        }}
                                        onFieldValueChanged={(newValue) => {
                                            this.setState({countingRuleName: newValue})
                                        }}
                                    />

                                <this.GenQuickMultiSearchDropdown
                                    required={true}
                                    disabled={this.state.IsCountingRuleInUse || this.state.viewMode === 'view'}
                                    active={this.state.viewMode === 'view' || this.state.IsCountingRuleInUse ? false : true} dataName="countingFrequency"
                                    placeholder={"counting_rule_frequence_placeholder"} tooltip={"counting_rule_frequence_tooltip"} title={"counting_rule_frequence"}
                                    selectedCodeId={typeof this.state.frequencyCodeId !== 'undefined' ? this.state.frequencyCodeId : undefined}
                                    t={t}
                                    storeHelper={this.props.storeHelper}
                                    submitAttempted={this.state.submitAttempted}
                                    callThis={this.handleSelection}
                                />

                                <ValidatedInputField
                                        name={"ruleStartTime"}
                                        fieldName={this.props.t('addCountingRule:counting_rule_rule_begins')}
                                        tooltip={this.props.t('addCountingRule:counting_rule_rule_begins_tooltip')}
                                        placeholder={this.props.t('addCountingRule:counting_rule_rule_begins_placeholder')}
                                        required={true}
                                        disabled={this.state.viewMode === 'view'  || (this.state.IsCountingRuleInUse && this.state.viewMode === 'edit') ? true : false}
                                        minLength={0}
                                        maxLength={100}
                                        type={"text"}
                                        fieldId={'ruleStartTime'}
                                        validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                        value={this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleStart}
                                        validationErrorMessage={this.props.t('addCountingRule:error_message_time_value')}
                                        validatorFunction={(x) => {

                                            let splittedDate = x.split('.');

                                            if(splittedDate.length === 3){

                                                if(parseInt(splittedDate[1]) > 0 && parseInt(splittedDate[1]) <= 12 && parseInt(splittedDate[0]) > 0 && parseInt(splittedDate[0]) <= daysInMonths[parseInt(splittedDate[1]-1)]){
                                                    return true;
                                                }
                                            }
                                            return false;
                                        }}
                                        onFieldValueChanged={(newValue) => {
                                            this.props.dispatch(countingRuleDropdownDataSelector.changeCountingRuleStart(newValue));
                                            this.setState({pageUpdated: true});
                                        }}
                                    />

                            <ValidatedInputField
                                        name={"ruleEndTime"}
                                        fieldName={this.props.t('addCountingRule:counting_rule_rule_ends')}
                                        tooltip={this.props.t('addCountingRule:counting_rule_rule_ends_tooltip')}
                                        placeholder={this.props.t('addCountingRule:counting_rule_rule_ends_placeholder')}
                                        required={true}
                                        minLength={0}
                                        disabled={this.state.viewMode === 'view'  || (this.state.IsCountingRuleInUse && this.state.viewMode === 'edit') ? true : false}
                                        maxLength={100}
                                        type={"text"}
                                        fieldId={'ruleEndTime'}
                                        validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                        value={this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleEnd}
                                        validationErrorMessage={this.props.t('addCountingRule:error_message_time_value')}
                                        validatorFunction={(x) => {

                                            let splittedDate = x.split('.');

                                            if(splittedDate.length === 3){
                                                if(parseInt(splittedDate[1]) > 0 && parseInt(splittedDate[1]) <= 12 && parseInt(splittedDate[0]) > 0 && parseInt(splittedDate[0]) <= daysInMonths[parseInt(splittedDate[1]-1)]){
                                                    return true;
                                                }
                                            }
                                            return false;
                                        }}
                                        onFieldValueChanged={(newValue) => {
                                            this.props.dispatch(countingRuleDropdownDataSelector.changeCountingRuleEnd(newValue));
                                            this.setState({pageUpdated: true});
                                        }}
                                      
                                    />

                                    <ValidatedInputField
                                        name={"coveragePercentage"}
                                        fieldName={this.props.t('addCountingRule:counting_rule_coverage_percentage')}
                                        tooltip={this.props.t('addCountingRule:counting_rule_coverage_percentage_tooltip')}
                                        placeholder={this.props.t('addCountingRule:counting_rule_coverage_percentage_placeholder')}
                                        required={true}
                                        minLength={0}
                                        maxLength={100}
                                        disabled={this.state.viewMode === 'view' || (this.state.IsCountingRuleInUse && this.state.viewMode === 'edit')   ? true : false}
                                        type={"text"}
                                        fieldId={'coveragePercentage'}
                                        validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                        value={this.state.coveragePercentage}
                                        validationErrorMessage={this.props.t('addCountingRule:error_message_percentage')}
                                        validatorFunction={(x) => {

                                            if(x.split(',').length > 2){
                                                return false;
                                            }

                                            let testOnlyContainsInts=new RegExp('^[1-9][0-9]*(,[0-9]+)?$');
                                            if(testOnlyContainsInts.test(x)){
                                                let floated=parseFloat(x.toString().replace(",","."));
                                                return floated >= 1 && floated <= 200.00;
                                            }
                                            return false;
                                        }}
                                        onFieldValueChanged={(newValue) => {
                                            this.setState({coveragePercentage: newValue});
                                        }}
                                        
                                    />

                                    <this.GenQuickMultiSearchDropdown
                                        required={true}
                                        disabled={this.state.IsCountingRuleInUse || this.state.viewMode === 'view'}
                                        active={this.state.viewMode === 'view' || this.state.IsCountingRuleInUse ? false : true} dataName="userType"
                                        placeholder={"counting_rule_userType_placeholder"} tooltip={"counting_rule_userType_tooltip"} title={"counting_rule_userType"}
                                        selectedCodeId={typeof this.state.userTypeCodeId !== 'undefined' ? this.state.userTypeCodeId : undefined}
                                        t={t}
                                        storeHelper={this.props.storeHelper}
                                        submitAttempted={this.state.submitAttempted}
                                        callThis={this.handleSelection}
                                    />
                            { (this.state.createdBy.toLowerCase() !== 'konversio')   &&     
                            
                            <this.GetFlowsSelectionList />
                                    }
                      
                      {(this.state.createdBy.toLowerCase() !== 'konversio')   &&
                            <div>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                        <th></th>
                                        <th>{t("addCountingRule:counting_rule_flow_table_header_flow_id")}</th>
                                        <th>{t("addCountingRule:counting_rule_flow_table_header_flow_name")}</th>
                                        <th>{t("addCountingRule:counting_rule_flow_table_header_flow_direction")}</th>
                                        <th>{t("addCountingRule:counting_rule_flow_table_header_flow_visitor_type")}</th>
                                        <th><label htmlFor={"weightFactor"} >{t("addCountingRule:counting_rule_flow_table_header_flow_weight")}</label>
                                        {t("addCountingRule:counting_rule_flow_weight_tooltip")!=='' && t("addCountingRule:counting_rule_flow_weight_tooltip")!==null &&
                                            <Tooltip title={t("addCountingRule:counting_rule_flow_weight_tooltip")}>
                                                <IconButton>
                                                    <InfoIcon/>

                                                </IconButton>
                                            </Tooltip>
                                            }
                                        
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.selectedFlows.map(flow => (
                                        <React.Fragment key={flow.flowId}>
                                        <tr key={flow.flowId}>
                                            <td>
                                                <div>
                                                    <button alt={""} type="button" onClick={() => {this.toggleRow(flow.flowId);}}>
                                                        {this.state.expandedRows.includes(flow.flowId) ? '-' : '+'}

                                                    </button>
                                                    {this.checkContainsFlowRuleSetFilters(flow.flowId) ? <img style={{width:"0.75em",filter: ""}} src={filterIcon} alt={""}/> : null}
                                                </div>
                                            </td>
                                            <td>{flow.flowId}</td>
                                            <td>{flow.name}</td>
                                            <td>{typeof flow.flowDirectionType !== 'undefined' && flow.flowDirectionType !== null ? flow.flowDirectionType.languages[flow.flowDirectionType.languages.findIndex(i => i.languageName === t('common:GMLLanguage'))].text:''}</td>
                                            <td>{typeof flow.userType !== 'undefined' && flow.userType !== null ? flow.userType.languages[flow.userType.languages.findIndex(i => i.languageName === t('common:GMLLanguage'))].text:''}</td>
                                            <td>
                                                <TextField id="weightFactor" type="number" inputProps={{step:"0.01", min:"-1", max:"2"}}  InputLabelProps={{shrink: true}}
                                                    value={this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.weightInputs.find(f => f.flowId === flow.flowId) !== undefined ? this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.weightInputs.find(f => f.flowId === flow.flowId).weight : 0} 
                                                    onChange={this.handleWeightChange.bind(this, flow.flowId)} disabled={this.state.viewMode === 'view'  || (this.state.IsCountingRuleInUse && this.state.viewMode === 'edit')}/>
                                            </td>
                                        </tr>
                                        {this.state.expandedRows.includes(flow.flowId) && (
                                            <tr>
                                              <td colSpan="6">
                                                <div>
                                                    {this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.hourLineSelections.find(f => f.flowId === flow.flowId).hourLineSelections.map((hourLineSelection, index) => (
                                                        <div key={index} style={{background: index % 2 ? '#f4f2ef' : '#ffffff'}}>
                                                        {hourLineSelection}
                                                           { index > 0 && <Button size={"small"} variant={"contained"} type="button" onClick={this.handleDeleteHourLineSelection.bind(this, index, flow.flowId)} 
                                                                disabled={this.state.viewMode === 'view'  || (this.state.IsCountingRuleInUse && this.state.viewMode === 'edit')}>
                                                                {this.props.t('addCountingRule:deleteNewHourLineSelection')}
                                                            </Button>}
                                                        </div>
                                                    ))}
                                                    <Button size={"small"} variant={"contained"} type="button" onClick={this.handleAddHourLineSelection.bind(this, flow.flowId)} 
                                                        disabled={this.state.viewMode === 'view' || (this.state.IsCountingRuleInUse && this.state.viewMode === 'edit')}>
                                                        {this.props.t('addCountingRule:addNewHourLineSelection')}
                                                    </Button>
                                                </div>
                                              </td>
                                            </tr>
                                          )}
                                        </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            
                         }

                            <div className="form-group">
                                <label className="form-checkbox">
                                    <input onChange={(e) => {
                                        this.setState({
                                            isEffective: e.target.checked
                                        });
                                    }} type="checkbox"
                                           checked={this.state.isEffective} disabled={this.state.viewMode === 'view' ? true : false}></input>
                                    <i className="form-icon"></i> <span>{this.props.t('addCountingRule:counting_rule_is_effect')}</span>
                                </label>
                            </div>

                            <ValidatedInputField
                                        name={"responsibleEmail"}
                                        fieldName={this.props.t('addCountingRule:counting_rule_responsible_name')}
                                        tooltip={this.props.t('addCountingRule:counting_rule_responsible_name_tooltip')}
                                        placeholder={this.props.t('addCountingRule:counting_rule_responsible_name_placeholder')}
                                        required={true}
                                        minLength={0}
                                        maxLength={200}
                                        disabled={this.state.viewMode === 'view' ? true : false}
                                        type={"text"}
                                        fieldId={'responsibleEmail'}
                                        validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                        value={this.state.responsibleEmail}
                                        validatorFunction={(x) => {
                                            if(x === ''){ return false };

                                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                            return emailRegex.test(x);
                                        }}
                                        validationErrorMessage={this.props.t('addCountingRule:error_message_email')}
                                        onFieldValueChanged={(newValue) => {
                                            this.setState({responsibleEmail: newValue})
                                        }}
                            />
                                
                            <ValidatedInputField
                                        name={"deputyEmail"}
                                        fieldName={this.props.t('addCountingRule:counting_rule_deputy_name')}
                                        tooltip={this.props.t('addCountingRule:counting_rule_deputy_name_tooltip')}
                                        placeholder={this.props.t('addCountingRule:counting_rule_deputy_name_placeholder')}
                                        required={false}
                                        disabled={this.state.viewMode === 'view' ? true : false}
                                        minLength={0}
                                        maxLength={200}
                                        type={"text"}
                                        fieldId={'deputyEmail'}
                                        validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                        validationErrorMessage={this.props.t('addCountingRule:error_message_email')}
                                        value={this.state.deputyEmail}
                                        validatorFunction={(x) => {
                                            if(x === ''){ return true };
                                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                            return emailRegex.test(x);
                                        }}
                                        onFieldValueChanged={(newValue) => {
                                            this.setState({deputyEmail: newValue})
                                        }}
                                        
                            />

                            <ValidatedInputField
                                        name={"additionalInfo"}
                                        fieldName={this.props.t('addCountingRule:counting_rule_additional_info')}
                                        tooltip={this.props.t('addCountingRule:counting_rule_additional_info_tooltip')}
                                        placeholder={this.props.t('addCountingRule:counting_rule_additional_info_placeholder')}
                                        required={false}
                                        disabled={this.state.viewMode === 'view' ? true : false}
                                        minLength={0}
                                        maxLength={1000}
                                        multiline={true}
                                        type={"text"}
                                        fieldId={'additionalInfo'}
                                        validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                        value={this.state.additionalInfo}
                                        validatorFunction={() => {
                                            return true
                                        }}
                                        onFieldValueChanged={(newValue) => {
                                            this.setState({additionalInfo: newValue})
                                        }}
                                        
                                />

                                <div className="form-group">
                                <label className="form-label"
                                   htmlFor={"changerName"}>{t('addCountingRule:counting_rule_createdBy')}
                                   { this.props.t('addCountingRule:counting_rule_createdBy_tooltip') !== null && <Tooltip title={this.props.t('addCountingRule:counting_rule_createdBy_tooltip')}>
                                        <IconButton>
                                            <InfoIcon/>

                                        </IconButton>
                                    </Tooltip>}
                            </label>
                                    <input className="form-input" type="text" id="changerName"
                                        value={this.state.createdBy}
                                        disabled={true}></input>
                                </div>

                                {this.state.viewMode === 'view' && 
                                    <div className="form-group">
                                        <label className="form-label"
                                            htmlFor={"createdTime"}>{t('addCountingRule:counting_rule_createdTime')}
                                            {this.props.t('addCountingRule:counting_rule_createdTime_tooltip') !== null && <Tooltip title={this.props.t('addCountingRule:counting_rule_createdTime_tooltip')}>
                                                <IconButton>
                                                    <InfoIcon/>

                                                </IconButton>
                                            </Tooltip>}

                                        </label>
                                        
                                        <input className="form-input" type="text" id="input-example-1"
                                            value={this.state.createdTime}
                                            disabled={true}></input>
                                    </div>
                                }

                               

                                <div className="form-group">
                                    <label className="form-label"
                                        htmlFor={"modifierName"}>{t('addCountingRule:counting_rule_modifiedBy')}
                                        {this.props.t('addCountingRule:counting_rule_modifiedBy_tooltip') !== null && <Tooltip title={this.props.t('addCountingRule:counting_rule_modifiedBy_tooltip')}>
                                            <IconButton>
                                                <InfoIcon/>

                                            </IconButton>
                                        </Tooltip>}

                                    </label>

                                    <input className="form-input" type="text" id="modifierName"
                                        value={this.state.modifiedBy}
                                        disabled={true}></input>
                                </div>


                                {this.state.viewMode === 'view' && 
                                    <div className="form-group">
                                        <label className="form-label"
                                            htmlFor={"modifiedTime"}>{t('addCountingRule:counting_rule_modifiedTime')}
                                            {this.props.t('addCountingRule:counting_rule_modifiedTime_tooltip') !== null && <Tooltip title={this.props.t('addCountingRule:counting_rule_modifiedTime_tooltip')}>
                                                <IconButton>
                                                    <InfoIcon/>
                                                </IconButton>
                                            </Tooltip>}

                                        </label>
                                        
                                        <input className="form-input" type="text" id="input-example-1"
                                            value={this.state.modifiedTime}
                                            disabled={true}></input>
                                    </div>
                                }


                            </FormController>

                            {        this.state.showSubmitAlert &&  <Alert onClose={() => {this.setState({showSubmitAlert: false})}} severity="error">
                                                <AlertTitle>{t("addCountingRule:error_message_header")}</AlertTitle>
                                                {t("addCountingRule:error_message_title_alert_box")} — <strong>{this.state.submitAlertMessage}</strong>
                                            </Alert>
                            }

                            <div>

                                {this.state.viewMode === 'edit' && 
                                    <Button size={"small"} variant={"contained"} type="button"
                                    onClick={async () => {
                                            this.setState({deleteRuleQuestionModal: true});
                                        }
                                    }
                                        disabled={this.state.IsCountingRuleInUse === false && (localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser") ? false : true}
                                        className=" float-left"><img style={{filter:"invert(100%)", height:"1.5vh"}} src="/img/icon/JHT design system/bin_fill.svg" alt={t("common:delete_button")} 
                                        />{t("common:delete_button")}</Button>}
                            
                                {this.state.viewMode === 'view' && 
                               
                                    <Button size={"small"} variant={"contained"} type="button"
                                       disabled={localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" ?  (this.state.createdBy.toLowerCase() !== 'konversio') ? false : true : true}
                                       className="float-right" onClick={() => {
                                            this.setState({viewMode: 'edit', pageUpdated: true}, () =>{this.props.dispatch(countingRuleTimeLimitsSelector.changeEditingState(this.state.IsCountingRuleInUse ? true : false))});
                                    }}><img style={{filter:"invert(100%)", height:"1.5vh"}} src="/img/icon/JHT design system/Pencil Write Alternate.svg" alt={""}/> {t("common:button_edit")}</Button>}



                               {this.state.viewMode !== 'view' && 
                               
                                <Button size={"small"} variant={"contained"} type="button"
                                        disabled={localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" ? false : true}
                                        className="float-right" onClick={() => {
                                    if (this.state.viewMode === 'edit') {
                                        this.setState({showEditingModal: true});
                                    } else {
                                        this.handleSubmit();
                                    }

                                }}><img style={{filter:"invert(100%)", height:"1.5vh"}} src="/img/icon/JHT design system/check_fill.svg" alt={""}/> {t("addCalcPlace:button_save")}</Button>}

                                {this.state.submitAttempted && <UploadingNotification showModal={this.state.submitAttempted} errors={this.state.submitErrors} type={"upload"} />}

                            </div>
                        </form>

                        <ModalComponent showModal={this.state.deleteRuleQuestionModal}
                                title={t("addCountingRule:title_delete_rule_question_title")}
                                content={t("addCountingRule:content_delete_rule_question_title")}
                                modalType={"submitModal"}
                                onModalContinue={async () => {
                                    this.setState({showErrorModal: false,isSubmitting:true},async  ()=>{
                                            await this.handleCountingRuleDelete();
                                    });

                                }}
                                onModalCancel={() => {
                                    this.setState({deleteRuleQuestionModal: false})
                                }}
                        />

                        <CountingDialog 
                            showModal={this.state.dialogOpen}
                            showSecondButton={this.state.showPublishButtonOnDialog}
                            title={this.state.currentTitle}
                            message={this.state.currentMessage}
                            onModalCancel={()=>this.setState({dialogOpen:false},  this.props.navigate("/countingRule/view/" + this.state.id_when_changed))}
                        />

                        <ModalComponent
                            showModal={this.state.submitErrorsModalOpen}
                            title={this.state.submitErrorsModalTitle}
                            content={this.state.submitErrorsModalContent}
                            modalType={"errorModal"}
                            onModalContinue={this.state.onModalContinue}
                        />

                        <ModalComponent showModal={this.state.showEditingModal}
                                title={t('addCountingRule:title_editingModal')}
                                content={t('addCountingRule:content_editingModal')}
                                modalType={"submitModal"}
                                onModalContinue={async () => {
                                    await this.handleSubmit();
                                    this.setState({showEditingModal: false});

                                }}
                                onModalCancel={() => {
                                    this.setState({showEditingModal: false})
                                }}
                        />
                   </>
            </>
        )
    }
}

export default withRouter(AddCountingRule);